@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,500,300italic,500italic,700italic,900,300);
:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

button, .button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  border-radius: var(--borders-radius);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  font-weight: var(--font-bold-weight);
  padding: 14px 56px;
  border: 0;
  outline: 0;
  font-size: 1em;
  font-size: var(--p-size);
  -webkit-transition: .5s;
  transition: .5s; }
  button.small, .button.small {
    padding: 8px 40px; }
  button.primary, .button.primary {
    color: #fff;
    background: #4579dc;
    background: var(--primary-color); }
  button[disabled], .button[disabled] {
    opacity: .75; }
  button:hover:not([disabled]), .button:hover:not([disabled]) {
    background: #265ec8; }

button.white,
.button.white {
  align-items: center;
  background: #fff;
  color: #4579dc;
  color: var(--primary-color);
  display: flex;
  justify-content: center; }
  button.white img,
  .button.white img {
    margin: .5em; }
  button.white:hover,
  .button.white:hover {
    background: #e6e6e6; }
  button.white.alert,
  .button.white.alert {
    color: #c44f4f;
    color: var(--alert-color); }
  button.white.alert:hover:not([disabled]),
  .button.white.alert:hover:not([disabled]) {
    color: #a83838; }

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://themes.googleusercontent.com/static/fonts/roboto/v9/Pru33qjShpZSmG3z6VYwnT8E0i7KZn-EPnyo3HZu7kw.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Regular"), local("Roboto-Regular"), url(https://themes.googleusercontent.com/static/fonts/roboto/v9/Xyjz-jNkfiYuJf8UC3Lizw.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://themes.googleusercontent.com/static/fonts/roboto/v9/oOeFwZNlrTefzLYmlVV1UD8E0i7KZn-EPnyo3HZu7kw.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://themes.googleusercontent.com/static/fonts/roboto/v9/97uahxiqZRoncBaCEI3aWz8E0i7KZn-EPnyo3HZu7kw.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url(https://themes.googleusercontent.com/static/fonts/roboto/v9/7m8l7TlFO-S3VkhHuR0at9Ih4imgI8P11RFo6YPCPC0.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"), url(https://themes.googleusercontent.com/static/fonts/roboto/v9/dFWsweFqlD8ExfyN7Gh_GPesZW2xOQ-xsNqO47m55DA.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url(https://themes.googleusercontent.com/static/fonts/roboto/v9/OLffGBTaF0XFOW1gnuHF0dIh4imgI8P11RFo6YPCPC0.woff) format("woff"); }

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(https://themes.googleusercontent.com/static/fonts/roboto/v9/t6Nd4cfPRhZP44Q5QAjcC9Ih4imgI8P11RFo6YPCPC0.woff) format("woff"); }

:root {
  --input-icon-width: 40px;
  --input-bg: #F7F7F7;
  --input-border: 1px solid #E0E0E0; }

form {
  display: grid;
  grid-gap: calc(15px * 2) 15px;
  grid-gap: calc(var(--grid-gap) * 2) var(--grid-gap);
  grid-template-columns: repeat(12, 1fr);
  grid-template-columns: repeat(var(--grid-columns), 1fr); }
  form .button {
    grid-column: span 12;
    grid-column: span var(--grid-columns);
    margin: 0 auto;
    width: 100%; }
  form .form--input {
    grid-column: span 12;
    grid-column: span var(--grid-columns);
    width: 100%;
    position: relative;
    display: flex; }
    form .form--input .icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px; }
    form .form--input.with-icon input {
      padding: 10px 15px 10px 40px;
      padding: 10px 15px 10px var(--input-icon-width); }

input {
  background: #F7F7F7;
  background: var(--input-bg);
  border: 1px solid #E0E0E0;
  border: var(--input-border);
  padding: 10px 15px;
  width: 100%; }

input::-webkit-input-placeholder {
  color: #CCC9C9; }

input::-moz-placeholder {
  color: #CCC9C9; }

input::-ms-input-placeholder {
  color: #CCC9C9; }

input::placeholder {
  color: #CCC9C9; }

.checkbox {
  font-size: 14px;
  position: relative;
  display: block;
  margin-bottom: 15px;
  cursor: pointer; }
  .checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .checkbox .checkbox-control {
    position: relative;
    left: 0;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background: #e6e6e6; }
  .checkbox:hover input ~ .checkbox-control,
  .checkbox:focus input ~ .checkbox-control {
    background: #ccc; }
  .checkbox input:checked ~ .checkbox-control {
    background: #4579dc;
    background: var(--primary-color); }
  .checkbox:hover input:not([disabled]):checked ~ .checkbox-control,
  .checkbox input:checked:focus ~ .checkbox-control {
    background: #4579dc;
    background: var(--primary-color); }
  .checkbox input:disabled ~ .checkbox-control {
    pointer-events: none;
    opacity: .6;
    background: #e6e6e6; }
  .checkbox .checkbox-control:after {
    position: absolute;
    display: none;
    content: ''; }
  .checkbox input:checked ~ .checkbox-control:after {
    display: block; }
  .checkbox .checkbox-control:after {
    top: 4px;
    left: 8px;
    width: 3px;
    height: 8px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 2px 2px 0; }
  .checkbox input:disabled ~ .checkbox-control:after {
    border-color: #0058ff; }

/* CM SPINNER */
.cm-spinner {
  height: 150px;
  width: 150px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 4px solid #f15e41;
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  position: relative; }

.cm-spinner::before,
.cm-spinner::after {
  content: "";
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 6px;
  right: 6px;
  border-radius: 50%;
  border: 4px solid transparent; }

.cm-spinner::before {
  border-top-color: #bad375;
  -webkit-animation: 3s spin linear infinite;
  animation: 3s spin linear infinite; }

.cm-spinner::after {
  border-top-color: #26a9e0;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

html {
  font-family: 'Roboto';
  font-family: var(--font-family);
  font-size: 16px;
  font-size: var(--font-size); }

* {
  font-family: 'Roboto';
  font-family: var(--font-family);
  box-sizing: border-box; }

.primary-font {
  color: #4579dc;
  color: var(--primary-color); }

.secondary-font {
  color: #f37474;
  color: var(--secondary-color); }

span, p, article {
  color: #455164;
  color: var(--font-color); }

article {
  --font-size: 14px;
  --p-size: var(--font-size);
  --h1-size: 1.5em;
  --h2-size: 1.25em; }
  article p {
    margin: .5rem 0; }
  article img {
    max-width: 100%; }

.relative {
  position: relative; }

.horizontal-center {
  display: flex;
  justify-content: center; }

.light {
  font-weight: 300; }

.normal {
  font-weight: 400; }

.bold {
  font-weight: 700; }

.text-center {
  text-align: center; }

h1, h2, h3, h4, h5, h6, p, strong {
  color: #455164;
  color: var(--font-color); }

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html, body, #root, main, .App {
  text-align: center;
  height: 100%; }

main.App > .background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%; }

h1 {
  font-size: 3em;
  font-size: var(--h1-size); }

h2 {
  font-size: 2em;
  font-size: var(--h2-size); }

h3 {
  font-size: 1.5em;
  font-size: var(--h3-size); }

h4 {
  font-size: 1.125em;
  font-size: var(--h4-size); }

h5 {
  font-size: 1em;
  font-size: var(--h5-size); }

p {
  font-size: 1em;
  font-size: var(--p-size); }

.on-mobile-only {
  display: none !important; }

@media screen and (max-width: 768px) {
  main:before {
    content: none; }
  img.background {
    display: none; }
  .on-mobile-only {
    display: flex !important; } }

.container {
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 1170px;
  width: var(--size-container-xlg); }

@media screen and (max-width: 1200px) {
  body .container {
    width: 970px;
    width: var(--size-container-lg); } }

@media screen and (max-width: 992px) {
  body .container {
    width: 750px;
    width: var(--size-container-md); } }

@media screen and (max-width: 768px) {
  body .container {
    width: 100%;
    width: var(--size-container-sm); } }

.pull-right {
  display: flex;
  justify-content: flex-end; }

.recipe-editor .rdw-editor-main {
  border: 1px solid #f1f1f1; }

.recipe-editor .rdw-editor-toolbar {
  margin-bottom: 0; }

.recipe-editor .rdw-dropdownoption-active {
  background: #e0e0e0; }

.recipe-editor .rdw-dropdownoption-default {
  color: #455164;
  color: var(--font-color);
  width: 100%;
  text-align: center; }

.recipe-editor .rdw-dropdown-wrapper .rdw-dropdown-optionwrapper {
  overflow-y: hidden;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.recipe-editor .public-DraftEditor-content {
  padding: 1rem; }

.recipe-editor .public-DraftStyleDefault-block {
  margin: 0;
  margin-bottom: 0.5rem; }

@media screen and (max-width: 1200px) {
  :root {
    --h1-size: 3em; }
  :root {
    --h2-size: 2em; }
  :root {
    --h3-size: 1.5em; }
  :root {
    --h4-size: 1.125em; }
  :root {
    --h5-size: 1em; }
  :root {
    --p-size: 1em; } }

@media screen and (max-width: 992px) {
  :root {
    --h1-size: 2.5em; }
  :root {
    --h2-size: 2em; }
  :root {
    --h3-size: 1.5em; }
  :root {
    --h4-size: 1.125em; }
  :root {
    --h5-size: 1em; }
  :root {
    --p-size: 1em; } }

@media screen and (max-width: 768px) {
  :root {
    --h1-size: 2em; }
  :root {
    --h2-size: 1.625em; }
  :root {
    --h3-size: 1.375em; }
  :root {
    --h4-size: 1.25em; }
  :root {
    --h5-size: 1em; }
  :root {
    --p-size: 1em; } }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

#login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }
  #login .container {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr;
    height: auto;
    max-width: 380px;
    width: 100%;
    background: white;
    padding: 5em 3em;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1); }
    #login .container > * {
      grid-column: span 1; }
  #login img {
    margin: auto; }
  #login h1 {
    font-weight: lighter;
    text-align: center;
    margin: 0; }

@media screen and (max-width: 768px) {
  #login .container {
    box-shadow: none;
    padding: 3em; }
  #login .info-graphic {
    max-width: 160px; } }

.loading {
  align-items: center;
  background: #ffffffb3;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%; }
  .loading.fullpage {
    background: #fff;
    display: flex;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2; }

/* CM SPINNER */
.cm-spinner {
  height: 150px;
  width: 150px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 4px solid #f15e41;
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  position: relative; }

.cm-spinner::before,
.cm-spinner::after {
  content: "";
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 6px;
  right: 6px;
  border-radius: 50%;
  border: 4px solid transparent; }

.cm-spinner::before {
  border-top-color: #bad375;
  -webkit-animation: 3s spin linear infinite;
  animation: 3s spin linear infinite; }

.cm-spinner::after {
  border-top-color: #26a9e0;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

#viewRecipe {
  width: 100%; }
  #viewRecipe .top {
    display: flex;
    justify-content: space-between; }
    #viewRecipe .top .left {
      display: flex;
      align-items: center; }
      #viewRecipe .top .left .secondary-font {
        text-decoration: none; }
      #viewRecipe .top .left h3 {
        margin: 0; }
      #viewRecipe .top .left .dropdown {
        align-self: flex-start;
        margin-top: 7px;
        margin-left: 10px; }
  #viewRecipe .options-grid {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 1fr 2fr;
    grid-gap: calc(15px * 2);
    grid-gap: calc(var(--grid-gap) * 2); }
    #viewRecipe .options-grid .selected-option span {
      display: none; }
  #viewRecipe .selectors {
    display: grid;
    grid-gap: 15px;
    grid-gap: var(--grid-gap);
    grid-template-columns: 150px 40px 40px 40px; }
  #viewRecipe .portions {
    align-self: center; }
  #viewRecipe .name-wrapper {
    display: flex;
    align-items: center; }
    #viewRecipe .name-wrapper input {
      max-width: 383px;
      width: 100%; }
    #viewRecipe .name-wrapper .portions {
      margin-left: 40px; }
  #viewRecipe h2 {
    margin-top: 0;
    margin-bottom: 1rem; }
  #viewRecipe .time-of-day {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(5, auto); }
    #viewRecipe .time-of-day .checkbox {
      display: flex;
      align-items: center; }
      #viewRecipe .time-of-day .checkbox input {
        width: 20px; }
  @media screen and (max-width: 768px) {
    #viewRecipe .top {
      margin-top: 1rem; }
    #viewRecipe .top .right {
      display: none; }
    #viewRecipe .selectors {
      grid-gap: 15px;
      grid-gap: var(--grid-gap);
      align-items: center;
      grid-template-columns: 125px 30px 30px 30px; }
    #viewRecipe .options-grid {
      grid-template-columns: 1fr; }
    #viewRecipe .options-grid .selectors .selected-option img {
      margin: 0; }
    #viewRecipe .dropdown .selected-option span {
      display: none; }
    #viewRecipe .mobile-hidden {
      display: none; }
    #viewRecipe .time-of-day {
      grid-template-columns: repeat(2, auto); } }

.switch {
  display: flex;
  align-items: center; }
  .switch label {
    color: var(--font-color);
    font-size: 14px;
    margin-right: 5px; }
  .switch input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    display: inline-block;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    height: 2em;
    width: 4em;
    font-size: .65rem;
    border-radius: 1.5em;
    background-color: #919FAF;
    border-color: transparent;
    background-clip: padding-box;
    color: #E2E5E5;
    vertical-align: middle;
    padding: 0;
    padding: initial;
    -webkit-transition: all 0.25s linear 0.25s;
    transition: all 0.25s linear 0.25s; }
    .switch input[type="checkbox"]:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 50%;
      background-color: white;
      border-radius: 100%;
      border: 0.125em solid transparent;
      background-clip: padding-box;
      z-index: 2;
      -webkit-transform-origin: right center;
              transform-origin: right center;
      -webkit-animation: switch-off-transform 0.25s ease-out forwards;
              animation: switch-off-transform 0.25s ease-out forwards; }
    .switch input[type="checkbox"]:after {
      position: absolute;
      left: 0.775em;
      top: 0;
      line-height: 2;
      font-family: "Ionicons";
      content: "\F2BC   ";
      letter-spacing: 0.2rem;
      color: #fff;
      font-weight: bold;
      z-index: 1; }
    .switch input[type="checkbox"]:focus {
      color: white;
      border-color: transparent;
      background-color: #919FAF;
      outline: none; }
  .switch input[type="checkbox"]:checked {
    color: white;
    background-color: #1ABC9C;
    border-color: transparent; }
    .switch input[type="checkbox"]:checked:before {
      -webkit-transform-origin: left center;
              transform-origin: left center;
      -webkit-animation: switch-on-transform 0.25s ease-out forwards;
              animation: switch-on-transform 0.25s ease-out forwards; }

@-webkit-keyframes switch-on-transform {
  0% {
    -webkit-transform: translate(0) scaleX(1);
            transform: translate(0) scaleX(1); }
  25% {
    -webkit-transform: translate(0) scaleX(1.33);
            transform: translate(0) scaleX(1.33); }
  100% {
    -webkit-transform: translate(100%) scaleX(1);
            transform: translate(100%) scaleX(1); } }

@keyframes switch-on-transform {
  0% {
    -webkit-transform: translate(0) scaleX(1);
            transform: translate(0) scaleX(1); }
  25% {
    -webkit-transform: translate(0) scaleX(1.33);
            transform: translate(0) scaleX(1.33); }
  100% {
    -webkit-transform: translate(100%) scaleX(1);
            transform: translate(100%) scaleX(1); } }

@-webkit-keyframes switch-off-transform {
  0% {
    -webkit-transform: translate(100%) scaleX(1);
            transform: translate(100%) scaleX(1); }
  25% {
    -webkit-transform: translate(100%) scaleX(1.33);
            transform: translate(100%) scaleX(1.33); }
  100% {
    -webkit-transform: translate(0) scaleX(1);
            transform: translate(0) scaleX(1); } }

@keyframes switch-off-transform {
  0% {
    -webkit-transform: translate(100%) scaleX(1);
            transform: translate(100%) scaleX(1); }
  25% {
    -webkit-transform: translate(100%) scaleX(1.33);
            transform: translate(100%) scaleX(1.33); }
  100% {
    -webkit-transform: translate(0) scaleX(1);
            transform: translate(0) scaleX(1); } }

.dropdown {
  position: relative;
  display: inline-block; }
  .dropdown[disabled] {
    opacity: .1; }
    .dropdown[disabled] .selected-option {
      cursor: default; }
    .dropdown[disabled] img {
      -webkit-filter: saturate(0.3);
              filter: saturate(0.3); }
  .dropdown .selected-option {
    display: flex;
    cursor: pointer;
    align-items: center; }
    .dropdown .selected-option img {
      margin-right: 10px; }
    .dropdown .selected-option span {
      font-size: .8rem;
      margin-right: 8px; }
  .dropdown .options {
    position: absolute;
    left: auto;
    right: auto;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 2; }
    .dropdown .options img {
      justify-self: center;
      align-self: center;
      margin: 0;
      max-width: 35px; }

.dropdown .options li {
  display: grid;
  cursor: pointer;
  display: grid;
  font-size: 12px;
  grid-gap: 5px;
  grid-template-columns: 35px 1fr 20px;
  padding: .5rem 1rem;
  width: 100%; }
  .dropdown .options li:hover {
    background: #f2f2f2; }
  .dropdown .options li .checkbox {
    margin-bottom: 0;
    margin-left: 5px; }
    .dropdown .options li .checkbox .checkbox-control {
      background: transparent; }
      .dropdown .options li .checkbox .checkbox-control:hover {
        background: transparent; }
      .dropdown .options li .checkbox .checkbox-control:after {
        border: solid #9E9E9E;
        border-width: 0 2px 2px 0; }

.portions {
  display: flex; }
  .portions .portion {
    cursor: pointer;
    margin: 0 5px; }
    .portions .portion:first-of-type {
      margin-left: 0; }
    .portions .portion:last-of-type {
      margin-right: 0; }
    .portions .portion:not(.selected) {
      -webkit-filter: grayscale(100%) brightness(1.6);
              filter: grayscale(100%) brightness(1.6); }

.ingredients-list .list-item {
  background: #fff;
  border-bottom: 1px solid #D6D6D6;
  color: #6B6B6B;
  font-size: 1rem;
  display: grid;
  grid-template-columns: 1fr 20px;
  padding: 5px 0; }
  .ingredients-list .list-item:last-of-type {
    border: none; }
  .ingredients-list .list-item textarea {
    border: 1px solid transparent;
    resize: none;
    box-sizing: border-box;
    overflow: hidden; }
    .ingredients-list .list-item textarea:focus {
      box-shadow: none;
      outline: none; }
    .ingredients-list .list-item textarea:disabled {
      background: transparent; }
  .ingredients-list .list-item .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center; }
    .ingredients-list .list-item .icon-wrapper img {
      cursor: pointer; }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

#editRecipe {
  width: 100%; }
  #editRecipe .top {
    display: flex;
    justify-content: space-between; }
    #editRecipe .top a {
      text-decoration: none; }
    #editRecipe .top .left {
      display: flex;
      align-items: center; }
      #editRecipe .top .left h3 {
        margin: 0; }
      #editRecipe .top .left .dropdown {
        margin-left: 10px; }
    #editRecipe .top .right {
      display: flex; }
  #editRecipe .options-grid {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 1fr 2fr;
    grid-gap: calc(15px * 2);
    grid-gap: calc(var(--grid-gap) * 2); }
    #editRecipe .options-grid .selected-option span {
      display: none; }
  #editRecipe .selectors {
    display: grid;
    grid-gap: 15px;
    grid-gap: var(--grid-gap);
    grid-template-columns: 150px 40px 40px 40px; }
  #editRecipe .portions {
    align-self: center; }
  #editRecipe .name-wrapper {
    display: flex;
    align-items: center; }
    #editRecipe .name-wrapper input {
      max-width: 383px;
      width: 100%; }
    #editRecipe .name-wrapper .portions {
      margin-left: 40px; }
  #editRecipe h2 {
    margin-top: 0;
    margin-bottom: 1rem; }
  #editRecipe .time-of-day {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(5, auto); }
    #editRecipe .time-of-day .checkbox {
      display: flex;
      align-items: center; }
      #editRecipe .time-of-day .checkbox input {
        width: 20px; }
  @media screen and (max-width: 768px) {
    #editRecipe .top {
      margin-top: 1rem; }
    #editRecipe .top .right {
      display: none; }
    #editRecipe .selectors {
      grid-gap: 15px;
      grid-gap: var(--grid-gap);
      align-items: center;
      grid-template-columns: 125px 30px 30px 30px; }
    #editRecipe .options-grid {
      grid-template-columns: 1fr; }
    #editRecipe .options-grid .selectors .selected-option img {
      margin: 0; }
    #editRecipe .dropdown .selected-option span {
      display: none; }
    #editRecipe .mobile-hidden {
      display: none; }
    #editRecipe .time-of-day {
      grid-template-columns: repeat(2, auto); } }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

#createRecipe {
  width: 100%; }
  #createRecipe .top {
    display: flex;
    justify-content: space-between; }
    #createRecipe .top a {
      text-decoration: none; }
    #createRecipe .top .left {
      display: flex;
      align-items: center; }
      #createRecipe .top .left h3 {
        margin: 0; }
      #createRecipe .top .left .dropdown {
        margin-left: 10px; }
  #createRecipe .options-grid {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 1fr 2fr;
    grid-gap: calc(15px * 2);
    grid-gap: calc(var(--grid-gap) * 2); }
    #createRecipe .options-grid .selected-option span {
      display: none; }
  #createRecipe .selectors {
    display: grid;
    grid-gap: 15px;
    grid-gap: var(--grid-gap);
    grid-template-columns: 150px 40px 40px 40px; }
  #createRecipe .portions {
    align-self: center; }
  #createRecipe .name-wrapper {
    display: flex;
    align-items: center; }
    #createRecipe .name-wrapper input {
      max-width: 383px;
      width: 100%; }
    #createRecipe .name-wrapper .portions {
      margin-left: 40px; }
  #createRecipe h2 {
    margin-top: 0;
    margin-bottom: 1rem; }
  #createRecipe .time-of-day {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(5, auto); }
    #createRecipe .time-of-day .checkbox {
      display: flex;
      align-items: center; }
      #createRecipe .time-of-day .checkbox input {
        width: 20px; }
  @media screen and (max-width: 768px) {
    #createRecipe .top {
      margin-top: 1rem; }
    #createRecipe .top .right {
      display: none; }
    #createRecipe .selectors {
      grid-gap: 15px;
      grid-gap: var(--grid-gap);
      align-items: center;
      grid-template-columns: 125px 30px 30px 30px; }
    #createRecipe .options-grid {
      grid-template-columns: 1fr; }
    #createRecipe .options-grid .selectors .selected-option img {
      margin: 0; }
    #createRecipe .dropdown .selected-option span {
      display: none; }
    #createRecipe .mobile-hidden {
      display: none; }
    #createRecipe .time-of-day {
      grid-template-columns: repeat(2, auto); } }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

#recipes {
  width: 100%;
  min-height: 430px; }
  #recipes .list {
    width: 50%; }
  #recipes .container {
    justify-content: initial; }
  #recipes h3 {
    margin-top: 1rem; }
  #recipes #searchRecipes {
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    padding: 2em 1rem;
    position: absolute;
    right: 0;
    top: 4em;
    -webkit-transition: 1s;
    transition: 1s; }
    #recipes #searchRecipes .dropdown .selected-option {
      font-size: 13px; }
    #recipes #searchRecipes .searchRecipes--filters {
      display: flex;
      justify-content: space-around; }
    #recipes #searchRecipes[data-is-closed="true"] {
      cursor: pointer;
      width: 35px;
      overflow: hidden; }
      #recipes #searchRecipes[data-is-closed="true"] .searchRecipes--body {
        display: none; }
    #recipes #searchRecipes .searchRecipes--header {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
      justify-content: space-between; }
      #recipes #searchRecipes .searchRecipes--header .searchRecipes--close {
        cursor: pointer; }
        #recipes #searchRecipes .searchRecipes--header .searchRecipes--close img {
          margin-right: 10px; }
    #recipes #searchRecipes .searchRecipes--tab {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      height: 100%; }
      #recipes #searchRecipes .searchRecipes--tab h4 {
        opacity: .2; }
      #recipes #searchRecipes .searchRecipes--tab[hidden] {
        display: none; }
    #recipes #searchRecipes h4 {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      font-size: 14px; }
    #recipes #searchRecipes h3.searchRecipes--title {
      color: #ACB3B9;
      color: var(--light-font-color);
      font-weight: 300;
      margin-bottom: 0;
      margin-top: 0; }
    #recipes #searchRecipes hr {
      border: 0;
      border-top: 1px solid #dfdfdf;
      display: block;
      height: 1px; }
    #recipes #searchRecipes .searchRecipes--name-wrapper {
      display: flex;
      justify-content: center;
      padding: 1rem 0; }
    #recipes #searchRecipes .searchRecipes--top-wrapper {
      align-items: center;
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: space-between; }
    #recipes #searchRecipes .switch {
      margin: 0; }
    #recipes #searchRecipes .searchRecipes--search-wrapper {
      margin-top: 1.5rem; }

@media screen and (max-width: 992px) {
  #recipes {
    display: grid;
    grid-template-areas: 'h3' 'search-recipes' 'items-list'; }
    #recipes .list .item:first-of-type {
      border-top: 1px solid #DFDFDF; }
    #recipes .top-wrapper {
      flex-direction: column;
      margin-top: 1rem; }
    #recipes h3 {
      grid-area: h3; }
    #recipes .list {
      grid-area: items-list;
      width: calc(100% + 30px);
      margin-left: -15px; }
      #recipes .list .item {
        padding: 15px; }
    #recipes #searchRecipes {
      box-shadow: none;
      grid-area: search-recipes;
      position: static;
      padding: .5rem 0 2rem;
      height: auto; }
      #recipes #searchRecipes .searchRecipes--close {
        display: none; }
      #recipes #searchRecipes hr {
        margin-left: -1rem;
        width: calc(100% + 2rem); }
      #recipes #searchRecipes .select-slider {
        margin-left: -1rem; }
        #recipes #searchRecipes .select-slider .select-slider--items {
          margin: auto; }
          #recipes #searchRecipes .select-slider .select-slider--items label {
            margin: 0 .5rem; } }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

.item {
  text-decoration: none;
  align-items: center;
  display: grid;
  grid-gap: calc(15px * 2);
  grid-gap: calc(var(--grid-gap) * 2);
  grid-template-columns: 1fr auto;
  justify-items: left;
  border-bottom: 1px solid #DFDFDF;
  padding: 15px 50px 15px 0px; }
  .item .options {
    display: grid;
    grid-gap: calc(15px * 1.3);
    grid-gap: calc(var(--grid-gap) * 1.3);
    grid-template-columns: 60px 28px 28px 28px 28px;
    align-items: center; }
  .item .switch label,
  .item .options span {
    display: none; }
  .item h4 {
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    margin: 0; }
  .item .selected {
    display: flex;
    align-items: center; }
    .item .selected img {
      margin-right: 10px; }
    .item .selected span {
      font-size: .8rem; }

@media screen and (max-width: 768px) {
  .item {
    grid-template-columns: initial;
    grid-gap: 15px;
    grid-gap: var(--grid-gap);
    grid-template-areas: 'name' 'options';
    width: 100%; }
    .item .name {
      grid-area: name; }
    .item .options {
      grid-area: options;
      display: grid;
      grid-template-columns: repeat(5, auto);
      grid-gap: calc(15px * 1.5);
      grid-gap: calc(var(--grid-gap) * 1.5); } }

.select-slider {
  align-items: center;
  display: flex;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .select-slider .select-slider--left-button,
  .select-slider .select-slider--right-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: .7rem 1rem; }
    .select-slider .select-slider--left-button[disabled],
    .select-slider .select-slider--right-button[disabled] {
      cursor: default;
      opacity: .5; }
  .select-slider .select-slider--items {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 1rem;
    min-width: 250px; }
    .select-slider .select-slider--items .checkbox {
      display: flex;
      align-items: center;
      font-size: 12px; }
    .select-slider .select-slider--items > * {
      cursor: pointer;
      margin: 0 1.3rem; }
      .select-slider .select-slider--items > *[disabled] {
        opacity: .3; }
      .select-slider .select-slider--items > *:first-of-type {
        margin-left: 0; }
      .select-slider .select-slider--items > *:last-of-type {
        margin-right: 0; }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

header {
  align-items: center;
  background: #4579dc;
  background: var(--primary-color);
  display: flex;
  height: 84px;
  z-index: 3; }
  header .mobile-only {
    display: none; }
  header span, header p {
    color: #fff; }
  header .button {
    padding: .3rem 1rem;
    text-transform: uppercase; }
    header .button img {
      height: 20px;
      width: 20px; }
  header a {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    text-decoration: none; }

.container {
  display: flex;
  justify-content: space-between;
  padding: 1em 0; }

img.white {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1); }

ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0; }
  ul li, ul a {
    display: flex;
    justify-content: center;
    align-items: center; }
  ul li {
    color: #fff;
    padding: 0 1.3rem; }
    ul li > div {
      display: flex;
      justify-content: center;
      align-items: center; }
    ul li:first-of-type {
      padding-left: 0; }
    ul li:last-of-type {
      padding-right: 0; }
    ul li img {
      margin-right: .8em; }
    ul li a {
      color: #fff;
      text-decoration: none; }
  ul .logo {
    display: none; }

.logout {
  cursor: pointer; }

@media screen and (max-width: 768px) {
  header {
    height: auto;
    position: fixed;
    top: 0;
    width: 100%; }
    header .container {
      padding: 15px 0; }
    header .mobile-only {
      display: block; }
    header .right-nav li {
      padding: 5px; }
    header .logo {
      display: block;
      margin-left: 1rem;
      margin-left: var(--mobile-padding); }
    header .left-nav li:not(.logo) {
      display: none; }
    header .right-nav li span {
      display: none; } }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

main #panel {
  background: white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  height: auto;
  margin: 0 auto;
  padding: 2em;
  position: relative;
  text-align: left; }

small.version {
  text-align: center;
  padding: 15px auto;
  color: #aaaaaa;
  font-weight: 200; }

@media screen and (max-width: 768px) {
  main #panel {
    padding: 1rem;
    padding: var(--mobile-padding);
    padding-bottom: 2rem;
    padding-top: 70px; } }

:root {
  --borders-size: 1px;
  --borders-radius: 20px;
  --mobile-padding: 1rem;
  --grid-columns: 12;
  --grid-gap: 15px;
  --h1-size: 3em;
  --h2-size: 2em;
  --h3-size: 1.5em;
  --h4-size: 1.125em;
  --h5-size: 1em;
  --p-size: 1em;
  --size-container-xlg: 1170px;
  --size-container-lg: 970px;
  --size-container-md: 750px;
  --size-container-sm: 100%;
  --primary-color: #4579dc;
  --secondary-color: #f37474;
  --alert-color: #c44f4f;
  --success-color: #5e931a; }

:root {
  --font-color: #455164;
  --light-font-color: #ACB3B9;
  --footer-font-color: #ACB3B9;
  --font-size: 16px;
  --font-family: 'Roboto';
  --font-light-weight: 300;
  --font-regular-weight: 400;
  --font-medium-weight: 500;
  --font-bold-weight: 700; }

#toast {
  align-items: center;
  display: flex;
  left: 0;
  padding: 1rem 1rem;
  position: fixed;
  top: 0;
  -webkit-transition: .2s;
  transition: .2s;
  width: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 5; }
  #toast i {
    font-size: 20px; }
  #toast span {
    color: white;
    margin-left: 10px;
    text-align: left; }
  #toast.error {
    background: #d33f3f;
    color: white; }
  #toast[data-visible="false"] {
    visibility: hidden;
    opacity: 0; }

